import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

/**
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const IFrameSection = ({ item }) => {
    return (
        <section className='pb-5 pt-3'>
            <Container>
                <Row className='justify-content-center mb-5'>
                    <Col lg='8'>
                        <iframe
                            title={item.title}
                            src={item.src}
                            frameBorder='0'
                            width='100%'
                            style={{
                                width: '100%',
                                height: item.height || '700px',
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default IFrameSection
