import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ContactForm from '../Forms/ContactForm'
import StyledSideCol from '../styled/StyledSideCol'
import { PortableText } from '@portabletext/react'
import portableTextComponents from '../portableTextComponents'

/**
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const ContactFormSection = ({ item }) => {
    return (
        <section id={item.formName.current} className='pb-5 pt-3'>
            <Container>
                <Row className='mb-4 pt-5'>
                    <StyledSideCol md='3' className='mb-3'>
                        <div className='text-secondary top'>{item.title}</div>
                        <div className='h3 bottom'>
                            <strong>{item.subtitle}</strong>
                        </div>
                    </StyledSideCol>
                    <Col>
                        <PortableText value={item._rawDescription} components={portableTextComponents} />
                    </Col>
                </Row>

                <ContactForm name={item.formName.current} />
            </Container>
        </section>
    )
}

export default ContactFormSection
