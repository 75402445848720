import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { PortableText } from '@portabletext/react'
import styled from 'styled-components'
import portableTextComponents from '../portableTextComponents'

/**
 * A component for rendering a sanity richTextSection
 *
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const RichTextSection = ({ item }) => {
    return (
        <RichTextSectionStyles id={item.sectionIdSlug?.current || item._key} className='py-5'>
            <Container>
                <Row className='justify-content-center mb-5'>
                    <Col lg='8'>
                        <PortableText value={item._rawBody} components={portableTextComponents} />
                    </Col>
                </Row>
            </Container>
        </RichTextSectionStyles>
    )
}

const RichTextSectionStyles = styled.article`
    h2,
    .h2,
    h3,
    .h3 {
        margin-top: 3rem;
    }
`

export default RichTextSection
