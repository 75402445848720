import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

/**
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const PosterLogosSection = ({ item }) => {
    return (
        <SectionStyles itemCount={item.items.length} className='d-flex justify-content-center overflow-hidden bg-dark'>
            <div className='grid'>
                {item.items.map(item => {
                    return (
                        <div key={item._key} className='grid-item'>
                            <GatsbyImage
                                alt={item.alt}
                                image={getImage(item.image.asset)}
                                placeholder='blurred'
                                loading='lazy'
                            />
                        </div>
                    )
                })}
            </div>
        </SectionStyles>
    )
}

const SectionStyles = styled.section`
    // Set the col amount based on item count
    --col-amount: ${props => props.itemCount || 6};
    --row-amount: 1;

    .grid {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(var(--col-amount), clamp(120px, 34vw, 170px));
        grid-template-rows: repeat(var(--row-amount), 1fr);
    }

    @media (max-width: 508px) {
        // On smaller screens, use 2 rows
        --row-amount: 2;
        --col-amount: 3;
    }
`

export default PosterLogosSection
