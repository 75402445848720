import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'

/**
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const LogosSection = ({ item }) => {
    return (
        <Container>
            <Row className='justify-content-center py-4'>
                <Col lg='8'>
                    <Row className='justify-content-center'>
                        {item.items.map(item => {
                            return (
                                <Col
                                    key={item._key}
                                    xs='4'
                                    md='3'
                                    className='p-3 d-flex justify-content-center align-items-center'>
                                    <GatsbyImage
                                        key={item._key}
                                        alt={item.alt}
                                        className='p-4'
                                        image={getImage(item.image.asset)}
                                        placeholder='blurred'
                                        loading='lazy'
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default LogosSection
